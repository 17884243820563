import PropTypes from 'prop-types';
import { getText } from '@veraio/strank';
import { Form, Input } from 'antd';
import { isNil } from 'lodash-es';
import { Button, Dropdown } from 'components/UIExternal';
import { EXTERNAL_PAYMENT } from 'enums';
import { FormBoxLoader } from '../Loader';
import { configurationSettingsInput, submitActions } from '../../styles';
import { BaseCheckbox } from 'components/ui/Checkboxes';
import { useLocations } from '@oneecosystem/dealshaker-core';

export const StripeSettings = ({ data, onUpdate }) => {
  const countries = useLocations((locationsState) => locationsState.countries);

  if (isNil(data) || isNil(countries)) return <FormBoxLoader number={1} />;

  const initialValues = {
    isStripeActive: data[EXTERNAL_PAYMENT.STRIPE.STRIPE_PAYMENT_METHOD_ACTIVE] === '1',
    stripeExpirationDays: data[EXTERNAL_PAYMENT.STRIPE.STRIPE_PAYMENT_INTENT_EXPIRATION_DAYS],
    stripeFixedFeeAmount: data[EXTERNAL_PAYMENT.STRIPE.STRIPE_FIXED_FEE_AMOUNT],
    stripeFeePercent: data[EXTERNAL_PAYMENT.STRIPE.STRIPE_FEE_PERCENT],
    stripeMinimalAmount: data[EXTERNAL_PAYMENT.STRIPE.STRIPE_MINIMAL_AMOUNT],
    stripeCountries: data[EXTERNAL_PAYMENT.STRIPE.STRIPE_COUNTRIES]
      ? JSON.parse(data[EXTERNAL_PAYMENT.STRIPE.STRIPE_COUNTRIES])
      : [],
  };

  const handleSubmit = (values) => {
    onUpdate({
      [EXTERNAL_PAYMENT.STRIPE.STRIPE_PAYMENT_METHOD_ACTIVE]: values.isStripeActive ? '1' : '0',
      [EXTERNAL_PAYMENT.STRIPE.STRIPE_PAYMENT_INTENT_EXPIRATION_DAYS]: values.stripeExpirationDays,
      [EXTERNAL_PAYMENT.STRIPE.STRIPE_FIXED_FEE_AMOUNT]: values.stripeFixedFeeAmount,
      [EXTERNAL_PAYMENT.STRIPE.STRIPE_FEE_PERCENT]: values.stripeFeePercent,
      [EXTERNAL_PAYMENT.STRIPE.STRIPE_MINIMAL_AMOUNT]: values.stripeMinimalAmount,
      [EXTERNAL_PAYMENT.STRIPE.STRIPE_COUNTRIES]: JSON.stringify(values.stripeCountries),
    });
  };

  return (
    <Form onFinish={handleSubmit} layout="vertical" initialValues={initialValues} err>
      <Form.Item className={configurationSettingsInput} name="isStripeActive" valuePropName="checked">
        <BaseCheckbox>{getText('isActive')}</BaseCheckbox>
      </Form.Item>
      <Form.Item
        className={configurationSettingsInput}
        label={getText('stripeExpirationDays')}
        name="stripeExpirationDays"
        required
        rules={[{ required: true, message: getText('fieldIsRequired') }]}>
        <Input />
      </Form.Item>
      <Form.Item
        className={configurationSettingsInput}
        label={getText('stripeFixedFeeAmount')}
        name="stripeFixedFeeAmount"
        required
        rules={[{ required: true, message: getText('fieldIsRequired') }]}>
        <Input />
      </Form.Item>
      <Form.Item
        className={configurationSettingsInput}
        label={getText('stripeFeePercent')}
        name="stripeFeePercent"
        required
        rules={[{ required: true, message: getText('fieldIsRequired') }]}>
        <Input />
      </Form.Item>
      <Form.Item
        className={configurationSettingsInput}
        label={getText('stripeMinimalAmount')}
        name="stripeMinimalAmount"
        required
        rules={[{ required: true, message: getText('fieldIsRequired') }]}>
        <Input />
      </Form.Item>
      <Form.Item className={configurationSettingsInput} name="stripeCountries" label={getText('stripeCountries')}>
        <Dropdown
          small
          withSearch
          multiSelect
          onTop
          placeholder={getText('worldWide')}
          options={countries}
          mapValue={(val) => val?.map((el) => el?.id)}
          displayKey="name"
          uniqueKey="id"
        />
      </Form.Item>
      <div css={submitActions}>
        <Button small type="secondary">
          {getText('submit')}
        </Button>
      </div>
    </Form>
  );
};

StripeSettings.propTypes = {
  data: PropTypes.object,
  onUpdate: PropTypes.func,
};
