import { useState } from 'react';
import { useDeepEffect, Dropdown, Button } from 'components/UIExternal';
import { useTranslations } from '@veraio/strank';
import { isNil } from 'lodash-es';
import { settlementsPeriodSelect, SETTLEMENTS_SETTINGS } from 'enums';
import { FormBoxLoader } from './Loader';
import { configurationSettingsInput, submitActions } from '../styles';

export const Settlements = ({ data, onUpdate }) => {
  const { getText } = useTranslations();
  const [values, setValues] = useState(data);

  useDeepEffect(() => {
    setValues(data);
  }, [data]);

  if (isNil(data)) return <FormBoxLoader number={6} />;

  return (
    <>
      <div css={configurationSettingsInput}>
        <span>{getText('merchant')}</span>
        <Dropdown
          small
          noClear
          options={settlementsPeriodSelect()}
          value={values?.[SETTLEMENTS_SETTINGS.SETTLEMENT_MERCHANT_PERIOD]}
          onChange={(e) =>
            setValues((prev) => ({ ...prev, [SETTLEMENTS_SETTINGS.SETTLEMENT_MERCHANT_PERIOD]: e.value }))
          }
        />
      </div>
      <div css={configurationSettingsInput}>
        <span>{getText('countryManager')}</span>
        <Dropdown
          small
          noClear
          options={settlementsPeriodSelect()}
          value={values?.[SETTLEMENTS_SETTINGS.SETTLEMENT_CM_PERIOD]}
          onChange={(e) => setValues((prev) => ({ ...prev, [SETTLEMENTS_SETTINGS.SETTLEMENT_CM_PERIOD]: e.value }))}
        />
      </div>
      <div css={configurationSettingsInput}>
        <span>{getText('areaManager')}</span>
        <Dropdown
          small
          noClear
          options={settlementsPeriodSelect()}
          value={values?.[SETTLEMENTS_SETTINGS.SETTLEMENT_AM_PERIOD]}
          onChange={(e) => setValues((prev) => ({ ...prev, [SETTLEMENTS_SETTINGS.SETTLEMENT_AM_PERIOD]: e.value }))}
        />
      </div>
      <div css={configurationSettingsInput}>
        <span>{getText('regionalManager')}</span>
        <Dropdown
          small
          noClear
          options={settlementsPeriodSelect()}
          value={values?.[SETTLEMENTS_SETTINGS.SETTLEMENT_RD_PERIOD]}
          onChange={(e) => setValues((prev) => ({ ...prev, [SETTLEMENTS_SETTINGS.SETTLEMENT_RD_PERIOD]: e.value }))}
        />
      </div>
      <div css={configurationSettingsInput}>
        <span>{getText('sponsorOfMerchant')}</span>
        <Dropdown
          small
          noClear
          options={settlementsPeriodSelect()}
          value={values?.[SETTLEMENTS_SETTINGS.SETTLEMENT_SPONSOR_PERIOD]}
          onChange={(e) =>
            setValues((prev) => ({ ...prev, [SETTLEMENTS_SETTINGS.SETTLEMENT_SPONSOR_PERIOD]: e.value }))
          }
        />
      </div>
      <div css={configurationSettingsInput}>
        <span>{getText('companyWallet')}</span>
        <Dropdown
          small
          noClear
          options={settlementsPeriodSelect()}
          value={values?.[SETTLEMENTS_SETTINGS.SETTLEMENT_COMPANY_PERIOD]}
          onChange={(e) =>
            setValues((prev) => ({ ...prev, [SETTLEMENTS_SETTINGS.SETTLEMENT_COMPANY_PERIOD]: e.value }))
          }
        />
      </div>
      <div css={configurationSettingsInput}>
        <span>{getText('marketingWallet')}</span>
        <Dropdown
          small
          noClear
          options={settlementsPeriodSelect()}
          value={values?.[SETTLEMENTS_SETTINGS.SETTLEMENT_MARKETING_PERIOD]}
          onChange={(e) =>
            setValues((prev) => ({ ...prev, [SETTLEMENTS_SETTINGS.SETTLEMENT_MARKETING_PERIOD]: e.value }))
          }
        />
      </div>
      <div css={configurationSettingsInput}>
        <span>{getText('additionalServicesWallet')}</span>
        <Dropdown
          small
          noClear
          options={settlementsPeriodSelect()}
          value={values?.[SETTLEMENTS_SETTINGS.SETTLEMENT_ADDITIONAL_SERVICES_PERIOD]}
          onChange={(e) =>
            setValues((prev) => ({ ...prev, [SETTLEMENTS_SETTINGS.SETTLEMENT_ADDITIONAL_SERVICES_PERIOD]: e.value }))
          }
        />
      </div>
      <div css={submitActions}>
        <Button small type="secondary" onClick={() => onUpdate(values)}>
          {getText('submit')}
        </Button>
      </div>
    </>
  );
};
