import PropTypes from 'prop-types';
import { getText } from '@veraio/strank';
import { Form, Input } from 'antd';
import { isNil } from 'lodash-es';
import { Button, Dropdown } from 'components/UIExternal';
import { EXTERNAL_PAYMENT } from 'enums';
import { FormBoxLoader } from '../Loader';
import { configurationSettingsInput, submitActions } from '../../styles';
import { rules } from 'utils/inputValidation';
import { BaseCheckbox } from 'components/ui/Checkboxes';
import { useLocations } from '@oneecosystem/dealshaker-core';

export const CoinPaymentsSettings = ({ data, onUpdate }) => {
  const countries = useLocations((locationsState) => locationsState.countries);

  if (isNil(data) || isNil(countries)) return <FormBoxLoader number={1} />;

  const initialValues = {
    currency: data[EXTERNAL_PAYMENT.COINPAYMENTS.COINPAYMENTS_CURRENCY],
    timeoutHours: Number(data[EXTERNAL_PAYMENT.COINPAYMENTS.COINPAYMENTS_REQUEST_TIMEOUT] ?? 0) / 60 / 60 || '',
    isActive: data[EXTERNAL_PAYMENT.COINPAYMENTS.COINPAYMENTS_ACTIVE] === '1',
    countries: data[EXTERNAL_PAYMENT.COINPAYMENTS.COINPAYMENTS_COUNTRIES]
      ? JSON.parse(data[EXTERNAL_PAYMENT.COINPAYMENTS.COINPAYMENTS_COUNTRIES])
      : [],
  };

  const handleSubmit = (values) => {
    onUpdate({
      [EXTERNAL_PAYMENT.COINPAYMENTS.COINPAYMENTS_CURRENCY]: values.currency,
      [EXTERNAL_PAYMENT.COINPAYMENTS.COINPAYMENTS_REQUEST_TIMEOUT]: values.timeoutHours * 60 * 60,
      [EXTERNAL_PAYMENT.COINPAYMENTS.COINPAYMENTS_ACTIVE]: values.isActive ? '1' : '0',
      [EXTERNAL_PAYMENT.COINPAYMENTS.COINPAYMENTS_COUNTRIES]: JSON.stringify(values.countries),
    });
  };

  return (
    <Form onFinish={handleSubmit} layout="vertical" initialValues={initialValues} err>
      <Form.Item className={configurationSettingsInput} name="isActive" valuePropName="checked">
        <BaseCheckbox>{getText('isActive')}</BaseCheckbox>
      </Form.Item>
      <Form.Item
        className={configurationSettingsInput}
        label={getText('coinpaymentsCurrency')}
        name="currency"
        required
        rules={[{ required: true, message: getText('fieldIsRequired') }]}>
        <Input />
      </Form.Item>
      <Form.Item
        className={configurationSettingsInput}
        label={getText('coinpaymentsTimeoutHours')}
        name="timeoutHours"
        required
        rules={[{ validator: (_, value) => rules.minMaxAsync(1, 168, value) }]}>
        <Input />
      </Form.Item>
      <Form.Item className={configurationSettingsInput} name="countries" label={getText('countries')}>
        <Dropdown
          small
          withSearch
          multiSelect
          onTop
          placeholder={getText('worldWide')}
          options={countries}
          mapValue={(val) => val?.map((el) => el?.id)}
          displayKey="name"
          uniqueKey="id"
        />
      </Form.Item>
      <div css={submitActions}>
        <Button small type="secondary">
          {getText('submit')}
        </Button>
      </div>
    </Form>
  );
};

CoinPaymentsSettings.propTypes = {
  data: PropTypes.object,
  onUpdate: PropTypes.func,
};
