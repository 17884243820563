import { useState } from 'react';
import PropTypes from 'prop-types';
import { getText } from '@veraio/strank';
import { isNil } from 'lodash-es';
import { Button, Dropdown, useDeepEffect } from 'components/UIExternal';
import {
  GLOBAL_CLEARING_AND_SETTLEMENT,
  RISK_LEVEL_BUSINESS_SELECT,
  RISK_LEVEL_CATEGORY_SELECT,
  RISK_LEVEL_DEAL_SELECT,
  RISK_LEVEL,
  RISK_LEVEL_VALUE,
  RISK_LEVEL_VALUE_INVERSE,
} from 'enums/settings';
import { FormBoxLoader } from './Loader';
import { configurationSettingsInput, submitActions } from '../styles';

export const GlobalClearingRiskLevels = ({ data, onUpdate }) => {
  const [values, setValues] = useState(data);

  useDeepEffect(() => {
    setValues(data);
  }, [data]);

  const handleChange = (keyValue) => {
    setValues((prev) => ({ ...prev, ...keyValue }));
  };

  const handleSubmit = () => onUpdate(values);

  if (isNil(data)) return <FormBoxLoader number={6} />;

  return (
    <>
      <div css={configurationSettingsInput}>
        <span>{getText('businessRiskLevel')}</span>
        <Dropdown
          small
          noClear
          options={RISK_LEVEL_BUSINESS_SELECT()}
          value={RISK_LEVEL_VALUE_INVERSE[values?.[GLOBAL_CLEARING_AND_SETTLEMENT.BUSINESS_RISK_LEVEL]]}
          onChange={(selection) =>
            handleChange({
              [GLOBAL_CLEARING_AND_SETTLEMENT.BUSINESS_RISK_LEVEL]: RISK_LEVEL_VALUE[selection.value],
            })
          }
        />
      </div>
      <div css={configurationSettingsInput}>
        <span>{getText('categoryRiskLevel')}</span>
        <Dropdown
          small
          noClear
          options={RISK_LEVEL_CATEGORY_SELECT()}
          value={RISK_LEVEL_VALUE_INVERSE[values?.[GLOBAL_CLEARING_AND_SETTLEMENT.CATEGORY_RISK_LEVEL]]}
          onChange={(selection) =>
            handleChange({
              [GLOBAL_CLEARING_AND_SETTLEMENT.CATEGORY_RISK_LEVEL]: RISK_LEVEL_VALUE[selection.value],
            })
          }
        />
      </div>
      <div css={configurationSettingsInput}>
        <span>{getText('dealRiskLevel')}</span>
        <Dropdown
          small
          noClear
          options={RISK_LEVEL_DEAL_SELECT()}
          value={
            values?.[GLOBAL_CLEARING_AND_SETTLEMENT.DEAL_RISK_LEVEL].toString() === '999'
              ? RISK_LEVEL.high
              : RISK_LEVEL_VALUE_INVERSE[values?.[GLOBAL_CLEARING_AND_SETTLEMENT.DEAL_RISK_LEVEL]]
          }
          onChange={(selection) =>
            handleChange({
              [GLOBAL_CLEARING_AND_SETTLEMENT.DEAL_RISK_LEVEL]:
                selection.value === RISK_LEVEL.high ? 999 : RISK_LEVEL_VALUE[selection.value],
            })
          }
        />
      </div>
      <div css={submitActions}>
        <Button small type="secondary" onClick={handleSubmit}>
          {getText('submit')}
        </Button>
      </div>
    </>
  );
};

GlobalClearingRiskLevels.propTypes = {
  data: PropTypes.object,
  onUpdate: PropTypes.func,
  onReset: PropTypes.func,
};
