import { useRef, useState } from 'react';
import { Table, Button } from 'components/UIExternal';
import { TabPane, Tabs } from 'components/ui';
import { toast } from 'react-hot-toast';
import { useTranslations } from '@veraio/strank';
import { notificationType } from 'enums';
import Routes from 'enums/Routes';
import useError from 'services/errorHandling/useError';
import {
  getAllNotifications,
  deleteNotification,
  getManualNotifications,
  markNotificationAsRead,
} from 'services/api/notificationsService';
import { systemTableColumns, manualTableColumns } from './config';
import { container, titleStyle, markAsReadBtn, notificationsButtonWrapper } from './styles';

const notificationTypeTab = {
  system: 'system',
  manual: 'manual',
};

export const Notifications = (props) => {
  const { history } = props;
  const { getText } = useTranslations();
  const { setError } = useError();
  const [selected, setSelected] = useState([]);
  const [activeTab, setActiveTab] = useState(notificationTypeTab.system);
  const tableRef = useRef();

  const handleDeleteNotification = async (notificationId) => {
    const [, err] = await deleteNotification(notificationId);
    if (err) setError(err);
    tableRef.current?.refreshData();
  };

  const markSelectedAsRead = async (data) => {
    const hasBeenRead = data?.filter((el) => !el.isRead);
    if (hasBeenRead.length) {
      const [, err] = await markNotificationAsRead(hasBeenRead?.map((el) => el.id));
      err ? setError(err) : tableRef.current?.refreshData();
    }
    setSelected([]);
    toast.success(getText('successfullyRead'));
  };

  const onTabClickHandler = (newActiveTab) => {
    if (newActiveTab === activeTab) return;
    setActiveTab(newActiveTab);
  };

  const handleRowClick = (notification) => {
    markSelectedAsRead([notification]);
    history.push(notificationType[notification.type]?.url(notification.additionalData));
  };

  return (
    <div css={container}>
      <div className="header flex space-between flex-center flex-wrap" style={{ gap: '0.5em 1em' }}>
        <h1 css={titleStyle}>{getText('notifications')}</h1>
        <div css={notificationsButtonWrapper}>
          {activeTab === notificationTypeTab.system && (
            <Button
              disabled={!selected?.length}
              type="secondary"
              onClick={() => markSelectedAsRead(selected)}
              css={markAsReadBtn}>
              {getText('markAsRead')}
            </Button>
          )}

          <Button linkTo={Routes.CreateNotification}>{getText('createNotification')}</Button>
        </div>
      </div>

      <Tabs defaultActiveKey={activeTab} activeKey={activeTab} onTabClick={onTabClickHandler}>
        <TabPane key={notificationTypeTab.system} tab={getText('systemNotifications')}>
          <Table
            ref={tableRef}
            pageSize={5}
            onRowClick={handleRowClick}
            hasSelection
            onSelect={setSelected}
            // systemTableColumns should be function because it has getText inside which throw error if it is used before configuration
            columns={systemTableColumns()}
            getDataMethod={getAllNotifications}
          />
        </TabPane>
        <TabPane key={notificationTypeTab.manual} tab={getText('manualNotifications')}>
          <Table
            ref={tableRef}
            columns={manualTableColumns(handleDeleteNotification)}
            getDataMethod={getManualNotifications}
          />
        </TabPane>
      </Tabs>
    </div>
  );
};
