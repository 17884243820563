import queryString from 'query-string';
import { isNil, pick } from 'lodash-es';

export const getAllTransactionsParams = (options) => {
  const {
    orderId,
    externalTransactionId,
    merchantEmail,
    buyerEmail,
    creationDateFrom,
    creationDateTo,
    paymentProviderId,
  } = options;

  return queryString.stringify(
    {
      ...pick(options, ['pageNumber', 'pageSize']),
      ...(orderId && orderId.toString().length && { orderId }),
      ...(paymentProviderId && paymentProviderId.toString().length && { paymentProviderId }),
      ...(externalTransactionId && externalTransactionId.toString().length && { externalTransactionId }),
      ...(merchantEmail && merchantEmail?.length && { merchantEmail }),
      ...(buyerEmail && buyerEmail?.length && { buyerEmail }),
      ...(!isNil(creationDateFrom) && { startDate: creationDateFrom }),
      ...(!isNil(creationDateTo) && { endDate: creationDateTo }),
    },
    { arrayFormat: 'index' },
  );
};

export const getTransactionCommentsParams = (options) => {
  const { sort = { sortField: 'startDate', sortOption: 'asc' }, pageSize, pageNumber } = options;

  const mapSort = (newSort) => ({
    sortField: newSort.sortField === 'modifiedDate' ? 'updatedDate' : newSort.sortField,
    sortOption: newSort.sortOption,
  });

  return queryString.stringify(
    {
      ...(pageNumber && { pageNumber }),
      ...(pageSize && { pageSize }),
      ...(sort && mapSort(sort)),
    },
    { arrayFormat: 'index' },
  );
};

export const getTransactionFilterRequestParams = (options) => {
  const { statusId, merchantDetails, buyerDetails,
    receiverDetails, date, orderId, transactionId, currency, transactionsSorting } =
    options;

  return queryString.stringify(
    {
      ...pick(options, ['pageNumber', 'pageSize']),
      ...(orderId && orderId.toString().length > 0 && { orderId }),
      ...(transactionId && transactionId.toString().length > 0 && { transactionId }),
      ...(statusId && statusId.toString().length > 0 && { statusId }),
      ...(merchantDetails && merchantDetails?.toString().length > 0 && { merchantDetails }),
      ...(buyerDetails && buyerDetails?.toString().length > 0 && { buyerDetails }),
      ...(receiverDetails && receiverDetails?.toString().length > 0 && { receiverDetails }),
      ...(currency && currency?.length > 0 && { currency }),
      ...(transactionsSorting.toString() && transactionsSorting?.toString().length > 0 && { transactionsSorting }),
      ...(!isNil(date) && { date }),
    },
    { arrayFormat: 'index' },
  );
};
