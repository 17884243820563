import { useState, forwardRef } from 'react';
import { Button, getTextChangeProps } from 'components/UIExternal';
import { useTranslations } from '@veraio/strank';
import { isEmpty } from 'lodash-es';
import { isMobile } from 'react-device-detect';
import { Col, Row } from 'antd';
import { EditOutlined, CloseOutlined } from '@ant-design/icons';
import {
  MediaList,
  BusinessMainInformationPreview,
  BusinessAdditionalInformationPreview,
  BusinessAddressPreview,
  ContactInformationPreview,
  BusinessMainInformationEdit,
  BusinessAdditionalInformationEdit,
  BusinessAddressEdit,
  ContactInformationEdit,
  AddressCard,
} from 'components';
import { mb } from 'styles';
import { informationWrapper, subTitle, specificBusinessInfoWrapper } from './styles';
import { editFormSectionBtn } from './styles';

export const BusinessInformationTab = forwardRef(
  ({ business, handleEditBusiness, businessWithAddress, oldData, newData }) => {
    const { getText } = useTranslations();
    const [isEditingMainInfo, setIsEditingMainInfo] = useState(false);
    const [isEditingAdditionalInfo, setIsEditingAdditionalInfo] = useState(false);
    const [isEditingBusinessAddress, setIsEditingBusinessAddress] = useState(false);
    const [isEditingContactInfo, setIsEditingContactInfo] = useState(false);

    const handleEditMainInformation = () => {
      setIsEditingMainInfo((prev) => !prev);
    };

    const handleEditBusinessAddress = () => {
      setIsEditingBusinessAddress((prev) => !prev);
    };

    const handleEditAdditionalInformation = () => {
      setIsEditingAdditionalInfo((prev) => !prev);
    };

    const handleEditContactInformation = () => {
      setIsEditingContactInfo((prev) => !prev);
    };

    const textChangeProps = getTextChangeProps(oldData, newData);

    return (
      <>
        {/* here starts the business components */}
        <Row css={[informationWrapper, mb({ lg: 36, span: 30 })]}>
          <Col lg={24} span={24}>
            <label css={subTitle}>{getText('mainInformation')}</label>
            <Button type="default" css={editFormSectionBtn} onClick={handleEditMainInformation}>
              {isEditingMainInfo ? <CloseOutlined /> : <EditOutlined />}
            </Button>
          </Col>
          {isEditingMainInfo ? (
            <BusinessMainInformationEdit
              business={business}
              onCancel={() => setIsEditingMainInfo(false)}
              onEdit={handleEditBusiness}
            />
          ) : (
            <BusinessMainInformationPreview business={business} textChange={textChangeProps} />
          )}
        </Row>
        <Row css={[specificBusinessInfoWrapper, mb({ lg: 36, span: 30 })]}>
          <Col lg={24} span={24}>
            <label css={subTitle}>{getText('additionalInformation')}</label>
            <Button type="default" css={editFormSectionBtn} onClick={handleEditAdditionalInformation}>
              {isEditingAdditionalInfo ? <CloseOutlined /> : <EditOutlined />}
            </Button>
          </Col>
          {isEditingAdditionalInfo ? (
            <BusinessAdditionalInformationEdit
              business={business}
              onCancel={() => setIsEditingAdditionalInfo(false)}
              onEdit={handleEditBusiness}
            />
          ) : (
            <BusinessAdditionalInformationPreview business={business} textChange={textChangeProps} />
          )}
        </Row>
        <Row css={[informationWrapper, mb({ lg: 36, span: 30 })]}>
          <Col lg={24} span={24}>
            <label css={subTitle}>{getText('contactInformation')}</label>
            <Button type="default" css={editFormSectionBtn} onClick={handleEditContactInformation}>
              {isEditingContactInfo ? <CloseOutlined /> : <EditOutlined />}
            </Button>
          </Col>
          {isEditingContactInfo ? (
            <ContactInformationEdit
              business={business}
              onCancel={() => setIsEditingContactInfo(false)}
              onEdit={handleEditBusiness}
            />
          ) : (
            <ContactInformationPreview business={business} textChange={textChangeProps} />
          )}
        </Row>
        <Row css={[informationWrapper, mb({ lg: 36, span: 30 })]}>
          <Col lg={24} span={24}>
            <label css={subTitle}>{getText('businessAddressInformation')}</label>
            <Button type="default" css={editFormSectionBtn} onClick={handleEditBusinessAddress}>
              {isEditingBusinessAddress ? <CloseOutlined /> : <EditOutlined />}
            </Button>
          </Col>
          {isEditingBusinessAddress ? (
            <BusinessAddressEdit
              business={business}
              onCancel={() => setIsEditingBusinessAddress(false)}
              onEdit={handleEditBusiness}
            />
          ) : (
            <BusinessAddressPreview business={business} textChange={textChangeProps} />
          )}
        </Row>
        {!isEmpty(business.media) && (
          <Row css={[informationWrapper, mb({ lg: 36, span: 30 })]}>
            <Col lg={24} span={24}>
              <label css={subTitle}>{getText('uploadedFiles')}</label>
            </Col>
            <MediaList showDownload media={business.media} css={mb({ lg: 10 })} />
          </Row>
        )}
        <Row>
          <Col lg={24} span={24} style={{ paddingLeft: !isMobile && 16 }}>
            <label css={subTitle}>{getText('pickupLocations')}</label>
          </Col>
        </Row>
        <Row css={mb({ lg: 30, span: 20 })} gutter={[24, 24]}>
          {businessWithAddress?.businessAddresses?.map((address, ind) => (
            <Col className="gutter-row" lg={8} span={24} key={ind}>
              <AddressCard oldData={address} />
            </Col>
          ))}
        </Row>
      </>
    );
  },
);
