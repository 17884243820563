import { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { pick, isNil } from 'lodash-es';
import { Form, Checkbox } from 'antd';
import { useTranslations } from '@veraio/strank';
import { isValidDate, isValidDaysPeriod } from 'utils/valueFormatter';
import { FormEditButtons } from 'components/shared';
import { DatePicker } from 'components/ui';

import { formDateStyle, datePickerStyles } from './styles';

const PromotionDetailsEdit = ({ deal, onCancel, onEdit }) => {
  const { getText } = useTranslations();
  const [showFiltersError, setShowFiltersError] = useState({ startDate: false, endDate: false });

  const isDealFromChina = deal.dealAddresses.some(
    (address) => address.country === 'China'
  );

  const newInitialValues = {
    ...pick(deal, 'startDate', 'endDate', 'isLockTimeRange'),
    startDate: moment(deal?.startDate),
    endDate: moment(deal?.endDate),
  };

  const handleSaveForm = async (values) => {
    await onEdit(values, ['startDate', 'endDate', 'isLockTimeRange']);
    onCancel();
  };

  const onFinishFailed = (errorInfo) => {
    if (errorInfo && isNil(errorInfo?.values.startDate)) setShowFiltersError((prev) => ({ ...prev, startDate: true }));
    if (errorInfo && isNil(errorInfo?.values.endDate)) setShowFiltersError((prev) => ({ ...prev, endDate: true }));
    if (errorInfo && !isNil(errorInfo?.values.endDate) && !isNil(errorInfo?.values.startDate))
      setShowFiltersError({ startDate: false, endDate: false });
  };

  return (
    <Form
      layout="vertical"
      initialValues={newInitialValues}
      onFinish={handleSaveForm}
      onFinishFailed={onFinishFailed}
      onFieldsChange={(changedValue) => {
        if (changedValue.find((el) => el.name.includes('startDate')))
          setShowFiltersError((prev) => ({ ...prev, startDate: false }));
        if (changedValue.find((el) => el.name.includes('endDate')))
          setShowFiltersError((prev) => ({ ...prev, endDate: false }));
      }}>
      <Form.Item
        label={getText('dealLaunchDate')}
        name="startDate"
        css={formDateStyle}
        shouldUpdate
        valuePropName="defaultValue"
        rules={[
          {
            required: true,
            message: getText('pleaseSpecifyStartDate'),
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || isValidDate(getFieldValue('startDate'))) return Promise.resolve();
              return Promise.reject(getText('startDateInPast'));
            },
          }),
        ]}>
        <DatePicker format="YYYY-MM-DD" css={datePickerStyles(showFiltersError.startDate)} />
      </Form.Item>
      <Form.Item
        label={getText('dealEndDate')}
        name="endDate"
        css={formDateStyle}
        shouldUpdate
        valuePropName="defaultValue"
        rules={[
          {
            required: true,
            message: getText('pleaseSpecifyEndDate'),
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || isValidDaysPeriod(getFieldValue('startDate'), getFieldValue('endDate')))
                return Promise.resolve();
              return Promise.reject(getText('endDateAfterStartDate'));
            },
          }),
        ]}>
        <DatePicker format="YYYY-MM-DD" css={datePickerStyles(showFiltersError.endDate)} />
      </Form.Item>
      {isDealFromChina && (
        <Form.Item
          name='isLockTimeRange'
          data-id="isLockTimeRange"
          valuePropName="checked"
        >
          <Checkbox
            checked={deal?.isLockTimeRange || false}
            onChange={(date) => {
              const isChecked = date.target.checked;
              deal.isLockTimeRange = isChecked;
            }}
          >
            <p>{getText('lockTimeRange')}</p>
          </Checkbox>
        </Form.Item>
      )}
      <FormEditButtons onCancel={onCancel} />
    </Form>
  );
};

PromotionDetailsEdit.propTypes = {
  deal: PropTypes.object,
  onCancel: PropTypes.func,
  onEdit: PropTypes.func,
};

export default PromotionDetailsEdit;
