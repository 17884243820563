import { getText } from '@veraio/strank';
import { invert } from 'lodash-es';

// Configuration group defined in the API response
export const CONFIG_GROUP = {
  DEAL_FEE: 'DEAL_FEE',
  DISTRIBUTION_FEE: 'DISTRIBUTION_FEE',
  MOBILE_VERSION: 'MOBILE_VERSION',
  WALLETS_FEE: 'WALLETS_FEE',
  CLEARING_AND_SETTLEMENT: 'CLEARING_AND_SETTLEMENT',
  EXTERNAL_PAYMENT_PROVIDERS: 'EXTERNAL_PAYMENT_PROVIDERS',
};

// INternal grouping of configurations, different from the group in the API
export const CONFIG_BOXES = {
  DEAL_FEE: 'DEAL_FEE',
  DISTRIBUTION_FEE: 'DISTRIBUTION_FEE',
  COUNTRY_CLEARING: 'COUNTRY_CLEARING',
  MOBILE_VERSION: 'MOBILE_VERSION',
  WALLETS_FEE: 'WALLETS_FEE',
  SETTLEMENT: 'SETTLEMENT',
  GLOBAL_CLEARING: 'GLOBAL_CLEARING',
  EXTERNAL_PAYMENT_COINPAYMENTS: 'EXTERNAL_PAYMENT_COINPAYMENTS',
  EXTERNAL_PAYMENT_STRIPE: 'EXTERNAL_PAYMENT_STRIPE',
};

export const DISTRIBUTION_FEE = {
  DEALS_FEE_FOR_COMPANY: 'DEALS_FEE_FOR_COMPANY',
  DEALS_FEE_FOR_COUNTRY_MANAGER: 'DEALS_FEE_FOR_COUNTRY_MANAGER',
  DEALS_FEE_FOR_AREA_MANAGER: 'DEALS_FEE_FOR_AREA_MANAGER',
  DEALS_FEE_FOR_REGIONAL_DIRECTOR: 'DEALS_FEE_FOR_REGIONAL_DIRECTOR',
  DEALS_FEE_FOR_SPONSOR: 'DEALS_FEE_FOR_SPONSOR',
  DEALS_FEE_FOR_TO_THE_MARKETING_BUDGET_OF_THE_PLATFORM: 'DEALS_FEE_FOR_TO_THE_MARKETING_BUDGET_OF_THE_PLATFORM',
  DEALS_FEE_FOR_ADDITIONAL_SERVICES_WALLET: 'DEALS_FEE_FOR_ADDITIONAL_SERVICES_WALLET',
};

export const DEAL_FEE = {
  WITH_90_100_PERCENT_CRYPTO: 'DEALS_FEE_WITH_90-100_PERCENT_CRYPTO',
  WITH_70_89_PERCENT_CRYPTO: 'DEALS_FEE_WITH_70-89_PERCENT_CRYPTO',
  WITH_50_69_PERCENT_CRYPTO: 'DEALS_FEE_WITH_50-69_PERCENT_CRYPTO',
  WITH_30_49_PERCENT_CRYPTO: 'DEALS_FEE_WITH_30-49_PERCENT_CRYPTO',
  WITH_10_29_PERCENT_CRYPTO: 'DEALS_FEE_WITH_10-29_PERCENT_CRYPTO',
  WITH_0_9_PERCENT_CRYPTO: 'DEALS_FEE_WITH_0-9_PERCENT_CRYPTO',
};

export const MOBILE_VERSION = {
  MOBILE_VERSION_MERCHANT_ANDROID: 'MOBILE_VERSION_MERCHANT_ANDROID',
  MOBILE_VERSION_MERCHANT_IOS: 'MOBILE_VERSION_MERCHANT_IOS',
  MOBILE_VERSION_BUYER_ANDROID: 'MOBILE_VERSION_BUYER_ANDROID',
  MOBILE_VERSION_BUYER_IOS: 'MOBILE_VERSION_BUYER_IOS',
};

export const WALLETS_FEE = {
  COMPANY_WALLET_ID: 'COMPANY_WALLET_ID',
  MARKETING_WALLET_ID: 'MARKETING_WALLET_ID',
  ADDITIONAL_SERVICE_WALLET_ID: 'ADDITIONAL_SERVICE_WALLET_ID',
  BACKUP_WALLET_ID: 'BACKUP_WALLET_ID',
  SETTLEMENT_ONELIFE_ACCOUNT_ID: 'SETTLEMENT_ONELIFE_ACCOUNT_ID',
};

export const EXTERNAL_PAYMENT = {
  COINPAYMENTS: {
    COINPAYMENTS_CURRENCY: 'COINPAYMENTS_CURRENCY',
    COINPAYMENTS_REQUEST_TIMEOUT: 'COINPAYMENTS_TIMEOUT_IN_SECONDS',
    COINPAYMENTS_COUNTRIES: 'COINPAYMENTS_COUNTRIES',
    COINPAYMENTS_ACTIVE: 'COINPAYMENTS_ACTIVE',
  },
  STRIPE: {
    STRIPE_PAYMENT_INTENT_EXPIRATION_DAYS: 'STRIPE_PAYMENT_INTENT_EXPIRATION_DAYS',
    STRIPE_PAYMENT_METHOD_ACTIVE: 'STRIPE_PAYMENT_METHOD_ACTIVE',
    STRIPE_FIXED_FEE_AMOUNT: 'STRIPE_FIXED_FEE_AMOUNT',
    STRIPE_FEE_PERCENT: 'STRIPE_FEE_PERCENT',
    STRIPE_MINIMAL_AMOUNT: 'STRIPE_MINIMAL_AMOUNT',
    STRIPE_COUNTRIES: 'STRIPE_COUNTRIES',
  },
};

export const SETTLEMENTS_SETTINGS = {
  SETTLEMENT_MERCHANT_PERIOD: 'SETTLEMENT_MERCHANT_PERIOD',
  SETTLEMENT_CM_PERIOD: 'SETTLEMENT_CM_PERIOD',
  SETTLEMENT_AM_PERIOD: 'SETTLEMENT_AM_PERIOD',
  SETTLEMENT_RD_PERIOD: 'SETTLEMENT_RD_PERIOD',
  SETTLEMENT_SPONSOR_PERIOD: 'SETTLEMENT_SPONSOR_PERIOD',
  SETTLEMENT_COMPANY_PERIOD: 'SETTLEMENT_COMPANY_PERIOD',
  SETTLEMENT_MARKETING_PERIOD: 'SETTLEMENT_MARKETING_PERIOD',
  SETTLEMENT_ADDITIONAL_SERVICES_PERIOD: 'SETTLEMENT_ADDITIONAL_SERVICES_PERIOD',
};

export const CLEARING_AND_SETTLEMENT = {
  BASIC_CLEARING_DAYS: 'BASIC_CLEARING_DAYS',
};

export const CLEARING_AND_SETTLEMENT_PERIOD = {
  daily: 'daily',
  weekly: 'weekly',
  monthly: 'monthly',
};

export const RISK_LEVEL = {
  high: 'high',
  moderate: 'moderate',
  low: 'low',
  no: 'no',
};

export const RISK_LEVEL_VALUE = {
  [RISK_LEVEL.high]: 42,
  [RISK_LEVEL.moderate]: 14,
  [RISK_LEVEL.low]: 7,
  [RISK_LEVEL.no]: 0,
};

export const RISK_LEVEL_VALUE_INVERSE = invert(RISK_LEVEL_VALUE);

export const GLOBAL_CLEARING_AND_SETTLEMENT = {
  BUSINESS_RISK_LEVEL: 'BUSINESS_RISK_LEVEL_DEFAULT',
  CATEGORY_RISK_LEVEL: 'CATEGORY_RISK_LEVEL_DEFAULT',
  DEAL_RISK_LEVEL: 'DEAL_RISK_LEVEL_DEFAULT',
};

export const RISK_LEVEL_BUSINESS_SELECT = () => [
  { value: RISK_LEVEL.high, label: getText('highRisk', { days: 42 }) },
  { value: RISK_LEVEL.low, label: getText('lowRisk', { days: 7 }) },
  { value: RISK_LEVEL.moderate, label: getText('moderateRisk', { days: 14 }) },
  { value: RISK_LEVEL.no, label: getText('noRisk', { days: 0 }) },
];

export const RISK_LEVEL_CATEGORY_SELECT = () => [
  { value: RISK_LEVEL.high, label: getText('highRisk', { days: 42 }) },
  { value: RISK_LEVEL.low, label: getText('lowRisk', { days: 7 }) },
  { value: RISK_LEVEL.moderate, label: getText('moderateRisk', { days: 14 }) },
  { value: RISK_LEVEL.no, label: getText('noRisk', { days: 0 }) },
];

export const RISK_LEVEL_DEAL_SELECT = () => [
  { value: RISK_LEVEL.high, label: getText('dealsHighRisk') },
  { value: RISK_LEVEL.moderate, label: getText('moderateRisk', { days: 7 }) },
  { value: RISK_LEVEL.no, label: getText('noRisk', { days: 0 }) },
];

export const settlementsPeriodSelect = () =>
  Object.entries(CLEARING_AND_SETTLEMENT_PERIOD).map(([label, value]) => ({
    label: getText(label),
    value,
  }));
