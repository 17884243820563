import { getText } from '@veraio/strank';
import { invert } from 'lodash-es';
import { TAG_TYPE } from 'components/UIExternal/Tag';
import { RISK_LEVEL } from './settings';

export const dealStatus = {
  pending: 1,
  approved: 2,
  declined: 3,
  expired: 4,
  closed: 5,
  soldOut: 6,
  draft: 7,
  suspended: 8,
};

export const dealStatusColor = {
  [dealStatus.pending]: TAG_TYPE.YELLOW,
  [dealStatus.approved]: TAG_TYPE.GREEN,
  [dealStatus.declined]: TAG_TYPE.RED,
  [dealStatus.expired]: TAG_TYPE.GRAY_LIGHT,
  [dealStatus.closed]: TAG_TYPE.RED_LIGHT,
  [dealStatus.soldOut]: TAG_TYPE.GRAY,
  [dealStatus.draft]: TAG_TYPE.BLUE_LIGHT,
  [dealStatus.suspended]: TAG_TYPE.GRAY_LIGHT,
};

export const dealStatusInverse = invert(dealStatus);

export const dealStatusSelect = () =>
  Object.entries(dealStatus).map(([label, value]) => ({ label: getText(label), value }));

export const deliveryMethod = {
  selfOrganizedShipping: 1,
  redeemAtLocation: 2,
  onlineService: 3,
};

export const deliveryMethodInverse = invert(deliveryMethod);

export const deliveryMethodSelect = {
  [deliveryMethod.selfOrganizedShipping]: {
    label: 'selfOrganizedShipping',
    icon: 'las la-truck',
  },
  [deliveryMethod.redeemAtLocation]: {
    label: 'redeemAtLocation',
    icon: 'las la-map-marker',
  },
  [deliveryMethod.onlineService]: {
    label: 'onlineService',
    icon: 'las la-globe',
  },
};

export const dealWeightType = {
  gr: 1,
  kg: 2,
};

export const dealWeightTypeSelect = [
  {
    label: 'kg',
    value: dealWeightType.kg,
  },
  {
    label: 'gr',
    value: dealWeightType.gr,
  },
];

export const dealRestrictedCategories = [
  'auto-and-moto',
  'car-electronics',
  'interior-accessories',
  'exterior-accessories',
  'auto-replacement-parts',
  'motorcycles',
  'maintenance-and-care',
  'auto-retail',
  'real-estates',
  'residential',
  'commercial',
  'industrial',
  'raw-land',
  'special-use',
];
export const disputeStatus = {
  pending: 1,
  approved: 2,
  declined: 3,
};

export const disputeStatusInverse = invert(disputeStatus);

export const disputeStatusColor = {
  [disputeStatus.pending]: TAG_TYPE.YELLOW,
  [disputeStatus.approved]: TAG_TYPE.GREEN,
  [disputeStatus.declined]: TAG_TYPE.RED,
};

export const disputeStatusSelect = () =>
  Object.entries(disputeStatus).map(([label, value]) => ({
    label: getText(label),
    value,
  }));

export const dealPriceSignDirection = {
  greater: 'greater',
  greaterOrEqual: 'greaterOrEqual',
  equal: 'equal',
  less: 'less',
  lessOrEqual: 'lessOrEqual',
};

export const dealPriceSignDirectionSelect = () =>
  Object.entries(dealPriceSignDirection).map(([label, value]) => ({
    label: getText(label),
    value,
  }));

export const dealRiskLevel = {
  [RISK_LEVEL.high]: 'dealsHighRisk',
  [RISK_LEVEL.moderate]: 'dealsModerateRisk',
  [RISK_LEVEL.no]: 'dealsNoRisk',
};

export const dealRiskLevelSelect = () =>
  Object.entries(dealRiskLevel).map(([label, value]) => ({
    label: getText(value),
    value: label,
  }));

export const dealImageProperties = {
  sizeLimit: 4194304,
  allowedFormats: ['image/jpeg', 'image/png'],
};
