import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslations } from '@veraio/strank';
import { notificationType } from 'enums/notification';
import { getTimeFromNow } from 'utils';
import { notificationContainer } from './style';

const Notification = ({ data, togglePopup }) => {
  const { getText } = useTranslations();
  const url = notificationType[data?.type]?.url(data?.additionalData);

  const contentContainer = () => {
    const message =
      url && data?.additionalData
        ? getText(`${data?.type}Message`, data?.additionalData)
        : data?.notificationManual?.message;

    const messageContainer = (
      <p title={message?.length > 60 ? message : null} className="message">
        {message?.length > 60 ? message.substring(0, 60).concat('...') : message}
      </p>
    );

    const title =
      url && data?.additionalData
        ? getText(`${data?.type}Title`, data?.additionalData)
        : data.notificationManual?.title;

    return (
      <div role="button" aria-hidden tabIndex={0} className="content" onClick={togglePopup}>
        <div className="titleTimeContainer">
          <h4 className="title">{title}</h4>
          <span className="time">{getTimeFromNow(data?.createdAt)}</span>
        </div>
        {messageContainer}
      </div>
    );
  };

  return url && data?.additionalData ? (
    <Link to={url} css={notificationContainer}>
      <img className="icon" src={notificationType[data?.type]?.icon} alt={data?.type} />
      {contentContainer()}
    </Link>
  ) : (
    <div css={notificationContainer}>
      <img className="icon" src={notificationType[data?.type]?.icon} alt={data?.type} />
      {contentContainer()}
    </div>
  );
};

Notification.propTypes = {
  data: PropTypes.object,
  togglePopup: PropTypes.func,
};

export default Notification;
