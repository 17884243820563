import { getText } from '@veraio/strank';
import { CRYPTO_CURRENCIES } from '@oneecosystem/dealshaker-core';
import { Tag, CopyText } from 'components/UIExternal';
import { TRANSACTION_STATUS_COLOR, TRANSACTION_STATUS_INVERSE } from 'enums';
import { PriceLabel } from 'components/shared';
import { validDate } from 'utils';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { textLinkStyle, textMutedStyle } from '../../styles';

export const getClearingColumns = () => [
  {
    name: getText('transactionIDAndDate'),
    width: 210,
    render: (row) => (
      <div>
        <p><Link css={textLinkStyle} to={`transactions/${row.id}/details`}>#{row.id}</Link></p>
        {!!row.externalId && row.externalId !== '0' && (<>
          <span>Ext #</span><CopyText css={{ fontSize: '0.7rem', display: 'flex', alignItems: 'center' }}>
            {row.externalId}
          </CopyText>
        </>)}
        <p css={textMutedStyle}>on {validDate(row.clearingDate) && moment(row.clearingDate).format('DD MMM YYYY')}</p>
      </div>
    ),
  },
  {
    name: getText('buyer'),
    render: row => (
      <div>
        <strong>{row.buyerName}</strong>
        <br />
        <span css={textMutedStyle}>{row.buyerPhone}</span>
        <br />
        <span css={textMutedStyle}>{row.buyerEmail}</span>
        <br />
        <span css={textMutedStyle}>{row.buyerNickname}</span>
      </div>
    ),
    width: 300,
  },
  {
    name: getText('merchant'),
    render: row => (
      <div>
        <strong>{row.receiverName}</strong>
        <br />
        <span css={textMutedStyle}>{row.receiverPhone}</span>
        <br />
        <span css={textMutedStyle}>{row.receiverEmail}</span>
        <br />
        <span css={textMutedStyle}>{row.receiverNickname}</span>
      </div>
    ),
    width: 300,
  },
  {
    name: getText('amount'),
    render: (row) => (
      <PriceLabel
        fiat={row.originalAmount}
        fiatOriginCurrency={row.originalCurrency}
        fiatDesiredCurrency={row.originalCurrency}
        crypto={row.oneCoinAmount || row.oespAmount}
        cryptoDesiredCurrency={row.oneCoinAmount ? CRYPTO_CURRENCIES.ONE.code : CRYPTO_CURRENCIES.OESP.code}
        cryptoOriginCurrency={row.oneCoinAmount ? CRYPTO_CURRENCIES.ONE.code : CRYPTO_CURRENCIES.OESP.code}
        fiatOnly={false}
      />
    ),
    width: 200,
  },
  {
    name: getText('reason'),
    render: row => <div>
      <p>{row.reason}</p><br />
      <p css={textMutedStyle}>{getText('orderId')}<Link css={textLinkStyle} to={`/orders?orderId=${row.orderId}`}>#{row.orderId}</Link></p>
    </div>
  },
  {
    name: getText('status'),
    render: row => (
      <Tag type={TRANSACTION_STATUS_COLOR[row.status]} fontSize="0.725rem">
        {getText(TRANSACTION_STATUS_INVERSE[row.status])}
      </Tag>
    ),
    width: 180,
  },
];

export const filterKeys = {
  orderId: 'orderId',
  transactionId: 'transactionId',
  merchantDetails: 'merchantDetails',
  buyerDetails: 'buyerDetails',
  date: 'date',
  statusId: 'statusId',
  currency: 'currency',
};

export const sortingKeys = {
  sorting: 'transactionsSorting',
};
