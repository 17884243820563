import { OrderDetailsHeader } from './Header';
import { OrderDetailsUsers } from './Users';
import { OrderDetailsDeals } from './Deals';
import { OrderDetailsDelivery } from './Delivery';
import { OrderDetailsPayment } from './Payment';
import { orderDetailsContainer } from './styles';

import { selectOrder, useOrders } from '../../../../stores/index.js';
import { useEffect } from 'react';
import { isNil } from '@veraio/core';

export const OrderDetails = ({ orderId = null }) => {
    const orderList = useOrders((ordersState) => ordersState?.orderList);
    useEffect(() => {
        if (!isNil(orderId) && !isNil(orderList)) {
            const selectedOrder = orderList?.find((order) => order.id === orderId);
            selectOrder(selectedOrder);
        }
    }, [orderId]);

    return (<section css={orderDetailsContainer}>
          <OrderDetailsHeader />
          <hr />
          <OrderDetailsUsers />
          <OrderDetailsDeals />
          <OrderDetailsPayment />
          <OrderDetailsDelivery />
      </section>
    );
};
