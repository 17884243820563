import { useState, useEffect, useRef, useMemo } from 'react';
import { Row, Col, Spin } from 'antd';
import moment from 'moment';
import { debounce } from 'lodash-es';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { toPng } from 'html-to-image';
import { AutoComplete, Input } from 'antd';
import { useTranslations } from '@veraio/strank';
import { useCurrencies, printPrice } from '@oneecosystem/dealshaker-core';
import { Button, CountryDropdown, DatePicker, StatisticsPdfTemplate, PageHeader } from 'components';
import LineChart from 'components/ui/Charts/Line/LineChart';
import BarChart from 'components/ui/Charts/Bar/BarChart';
import DoughnutChart from 'components/ui/Charts/Doughnut/DoughnutChart';
import { Icon } from 'components';
import { getStatisticsGraphs, getStatisticsOverview, getAllBusinesses, setError } from 'services';
import { mb } from 'styles';
import {
  couponsConfig,
  topSellingCategoriesConfig,
  paymentMethodConfig,
  totalSalesConfig,
  topMerchantBySoldCouponsConfig,
  topMerchantByTurnOverConfig,
} from './config';
import {
  couponsChart,
  overviewRow,
  overviewBox,
  borderBox,
  dailySalesTitleWrapper,
  container,
  filterRow,
  dateContainer,
  paymentChart,
  dropDownStyles,
  datePickerStyles,
} from './styles';

const Statistics = () => {
  const { getText, getDynamicTranslation } = useTranslations();
  const currency = useCurrencies((currenciesState) => currenciesState.selectedCurrency);
  const chartsReference = useRef();
  const [reportPdf, setReportPdf] = useState();
  const [data, setData] = useState({ statsOverview: {}, chartsData: {} });
  const [selectedData, setSelectedData] = useState({
    countryId: null,
    businessId: null,
    startDate: null,
    endDate: null,
    currencyId: currency?.currencyId,
  });
  const { statsOverview, chartsData } = data;

  const [searchResult, setSearchResult] = useState([]);
  const [searchText, setSearchText] = useState(null);

  const {
    totalSales,
    totalSalesCount,
    paymentMethods,
    topSellingCategories,
    topMerchantsBySoldCoupons,
    topMerchantsByTurnOver,
    ordersDelivery,
    ordersCount,
  } = chartsData;

  useEffect(() => {
    fetchGraphsData();
    fetchOverviewStats();
  }, [selectedData]);

  const updateFetchedData = (prop, value) => setData((prev) => ({ ...prev, [prop]: value }));

  const updateData = (prop, value) => setSelectedData((prev) => ({ ...prev, [prop]: value }));

  const fetchOverviewStats = async () => {
    const [res, err] = await getStatisticsOverview(selectedData);
    return err ? setError(err) : updateFetchedData('statsOverview', res);
  };

  const fetchGraphsData = async () => {
    const [res, err] = await getStatisticsGraphs(selectedData);
    err ? setError(err) : updateFetchedData('chartsData', res);
  };

  const onButtonClick = async () => {
    if (chartsReference.current === null) return;

    const response = await toPng(chartsReference.current);
    setReportPdf(response);
  };

  const onSearchChange = async (e) => {
    if (e?.length === 0) {
      updateData('businessId', null);
      setSearchResult([]);
    }
    if (e?.length >= 3) {
      const [res, err] = await getAllBusinesses({ searchText: e, countryIds: [selectedData.countryId] });
      err && setError(err);

      setSearchResult(
        (res.items ?? []).map((business) => ({
          value: business.id,
          label: getDynamicTranslation(business.name)?.name,
        })),
      );
    }
  };

  const handleSearch = useMemo(() => debounce(onSearchChange, 500), [selectedData]);

  const handleSelect = (val) => {
    setSearchText(val.label);
    updateData('businessId', val.value);
  };

  const handleClear = () => {
    if (searchText) {
      setSearchText(null);
      setSearchResult([]);
      onSearchChange(null);
      updateData('businessId', null);
    }
  };

  return (
    <div css={container}>
      <PageHeader title={getText('statisticsOverview')} />
      <Row>
        <div css={filterRow}>
          <div css={dropDownStyles}>
            <CountryDropdown
              placeholder={getText('country')}
              css={dateContainer}
              onChange={(e) => updateData('countryId', e)}
            />

            <AutoComplete
              autoClear
              css={dateContainer}
              notFoundContent={getText('noResultsFound')}
              options={searchResult}
              onSelect={(_val, option) => handleSelect(option)}
              value={searchText}
              onSearch={(e) => {
                handleSearch(e);
                setSearchText(e);
              }}
              placeholder={getText('searchForBusinesses')}>
              <Input
                suffix={<span>{searchText && <Icon iconName="las la-times" size={14} onClick={handleClear} />}</span>}
              />
            </AutoComplete>
          </div>

          <div css={datePickerStyles}>
            <div>
              <span>{getText('from')}</span>
              <DatePicker onChange={(e) => updateData('startDate', e)} />
            </div>
            <div>
              <span>{getText('to')}</span>
              <DatePicker onChange={(e) => updateData('endDate', e)} />
            </div>
          </div>
        </div>
      </Row>
      {statsOverview && chartsData ? (
        <div ref={chartsReference}>
          <div css={overviewRow}>
            <div css={overviewBox}>
              <p>{statsOverview.totalSales}</p>
              <p>{getText('totalSales')}</p>
            </div>
            <div css={overviewBox}>
              <p>{statsOverview.totalMerchants}</p>
              <p>{getText('totalMerchants')}</p>
            </div>
            <div css={overviewBox}>
              <p>{statsOverview.totalDeals}</p>
              <p>{getText('totalDeals')}</p>
            </div>
            <div css={overviewBox}>
              <p>{printPrice({ fiat: statsOverview?.totalRevenue, fiatOnly: false })}</p>
              <p>{getText('totalRevenue')}</p>
            </div>
            <div css={overviewBox}>
              <p>{statsOverview.packedOrders}</p>
              <p>{getText('pendingFulfillment')}</p>
            </div>
          </div>
          <div ref={chartsReference}>
            <Row>
              <Col lg={18} span={24} css={borderBox}>
                <LineChart
                  data={totalSales}
                  totalCount={totalSalesCount}
                  config={totalSalesConfig}
                  title={getText('totalSales')}
                  chartHeight="80px"
                />
              </Col>
              <Col lg={{ span: 5, offset: 1 }} span={24} css={borderBox}>
                <DoughnutChart
                  data={paymentMethods || []}
                  title={getText('paymentMethod')}
                  chartStyles={paymentChart}
                  config={paymentMethodConfig}
                  showPercentage
                  chartHeight="150px"
                />
              </Col>
              <Col lg={12} span={24} css={borderBox}>
                <BarChart
                  data={topSellingCategories}
                  config={topSellingCategoriesConfig}
                  title={getText('topSellingCategories')}
                  chartHeight="130px"
                />
              </Col>
              <Col lg={{ span: 11, offset: 1 }} span={24} css={borderBox}>
                <DoughnutChart
                  data={ordersDelivery || []}
                  totalCount={ordersCount}
                  chartStyles={couponsChart}
                  title={getText('orderDeliveries')}
                  showPercentage
                  config={couponsConfig}
                  chartHeight="200px"
                />
              </Col>
            </Row>
            <Row>
              <Col lg={12} span={24} css={borderBox}>
                <BarChart
                  data={topMerchantsBySoldCoupons}
                  config={topMerchantBySoldCouponsConfig}
                  title={getText('topMerchantsSoldCoupons')}
                  chartHeight="100px"
                />
              </Col>
              <Col lg={{ span: 11, offset: 1 }} span={24} css={borderBox}>
                <BarChart
                  data={topMerchantsByTurnOver}
                  config={topMerchantByTurnOverConfig}
                  title={getText('topMerchantsTurnover')}
                  chartHeight="100px"
                />
              </Col>
            </Row>
          </div>
          <Row css={dailySalesTitleWrapper}>
            <Col lg={11} span={24}>
              <label>{getText('dailySalesReport')}</label>
            </Col>
            <Col lg={{ span: 3, offset: 10 }} span={24}>
              <PDFDownloadLink
                document={
                  <StatisticsPdfTemplate
                    imageSrc={reportPdf}
                    {...statsOverview}
                    fromDateSales={selectedData.startDate}
                    toDateSales={selectedData.endDate}
                  />
                }
                fileName={`report-${moment().format('yyyy-mm-DD')}`}>
                {({ loading }) => (
                  <Button type="secondary" small onClick={onButtonClick}>
                    {loading ? getText('generateReport') : getText('downloadReport')}
                  </Button>
                )}
              </PDFDownloadLink>
            </Col>
          </Row>
        </div>
      ) : (
        <div css={mb({ lg: 100, span: 50 })} className="content-container alignTextCenter">
          <Spin size="large" />
        </div>
      )}
    </div>
  );
};

export default Statistics;
